import React, { useEffect, useState } from 'react';
import {
    Box, TextField, Button, Typography, MenuItem, FormControl,
    InputLabel, Select, Snackbar, CardContent, Card, CardActions, SelectChangeEvent
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ReviewService } from '../../services/ReviewService';
import CityList from '../../types/CityList';
import { ReviewData } from '../../types/ReviewTypes';

const reviewService = new ReviewService();

const AddEditReview = () => {
    const { reviewId } = useParams();
    const [reviewData, setReviewData] = useState<ReviewData>({
        id: '',
        tenantId: '',
        landlordName: '',
        landlordMobileNumber: '',
        city: '',
        homeType: '',
        homeAddress: '',
        rating: '',
        reviewText: '',
        startMonth: '',
        startYear: '',
        endMonth: '',
        endYear: '',
        homeConfiguration: '',
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const navigate = useNavigate();
    const [isOtherCity, setIsOtherCity] = useState(false);
    const isEditing = reviewId != null;

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const years = Array.from({ length: 15 }, (_, i) => (2010 + i).toString());

    useEffect(() => {
        if (isEditing) {
            const fetchReviewData = async () => {
                const result = await reviewService.getReview(reviewId);
                if (result.success) {
                    setReviewData(result.review!);
                    setIsOtherCity(!CityList.includes(result.review!.city));
                }
            };
            fetchReviewData();
        }
    }, [reviewId]);


    const handleSelectChange = (event: SelectChangeEvent<string>) => {
        const name = event.target.name as keyof ReviewData;
        const value = event.target.value;
        setReviewData(prev => ({ ...prev, [name]: value }));

    };

    const handleCityChange = (event: SelectChangeEvent<string>) => {
        const value = event.target.value;
        if (value === "Other") {
            setIsOtherCity(true);
            setReviewData(prev => ({ ...prev, city: '' }));
        } else {
            setIsOtherCity(false);
            setReviewData(prev => ({ ...prev, city: value }));
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setReviewData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault(); // Prevents the default action of the button (if any)

        if (reviewData.tenantId === '') {
            reviewData.tenantId = localStorage.getItem('userId') || '';
        }

        // Check if the required fields are selected
        if (!reviewData.homeType) {
            alert("Please select both Home Type");
            return; // Prevent the submission
        }
        if (!reviewData.homeConfiguration) {
            alert("Please select Home Configuration");
            return; // Prevent the submission
        }


        try {
            let response;
            if (isEditing) {
                response = await reviewService.updateReview(reviewId, reviewData);

            } else {
                response = await reviewService.addReview(reviewData);
            }

            if (response.success) {
                setSnackbarMessage(isEditing ? "Review updated successfully!" : "Review added successfully!");
                setSnackbarOpen(true);
                if (isEditing) {
                    setTimeout(() => {
                        navigate("/my-profile");
                    }, 1000);
                }
            } else {
                setSnackbarMessage("Failed to submit review: " + response.message);
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('Error submitting review:', error);
            setSnackbarOpen(true);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <Card sx={{ maxWidth: 800, mx: "auto", mt: 5, p: 3 }}>
            <CardContent>
                <Typography variant="h5" align="center" gutterBottom>
                    Share your experience with the world!
                </Typography>
                <Box component="form" noValidate sx={{ mt: 1 }}>
                    {/* Repeat this structured format for each TextField and Select */}
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="landlordName"
                        label="Landlord Name"
                        name="landlordName"
                        placeholder="Please enter the name of the landlord"
                        value={reviewData.landlordName}
                        onChange={handleChange}
                    />
                    {/* Continue adding fields with both id and name */}
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="landlordMobileNumber"
                        label="Landlord Mobile Number"
                        name="landlordMobileNumber"
                        type="number"
                        value={reviewData.landlordMobileNumber.toString()}
                        onChange={handleChange}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel>City</InputLabel>
                        <Select
                            name="city"
                            value={isOtherCity ? "Other" : reviewData.city}
                            required
                            label="City"
                            onChange={handleCityChange}
                        >
                            {CityList.map((city) => (
                                <MenuItem key={city} value={city}>{city}</MenuItem>
                            ))}
                            <MenuItem value="Other">Other</MenuItem>
                        </Select>
                    </FormControl>
                    {isOtherCity && (
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="city"
                            label="Other City"
                            name="city"
                            placeholder="Please enter your city"
                            value={reviewData.city}
                            onChange={handleChange}
                        />
                    )}
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Home Type</InputLabel>
                        <Select
                            name="homeType"
                            value={reviewData.homeType}
                            label="Home Type"
                            onChange={handleSelectChange}
                        >
                            <MenuItem value="Apartment">Apartment</MenuItem>
                            <MenuItem value="Floor">Floor</MenuItem>
                            <MenuItem value="PG">PG</MenuItem>
                            <MenuItem value="Independent House">Independent House</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Home Configuration</InputLabel>
                        <Select
                            name="homeConfiguration"
                            value={reviewData.homeConfiguration}
                            label="Home Configuration"
                            onChange={handleSelectChange}
                        >
                            <MenuItem value="Studio">Studio</MenuItem>
                            <MenuItem value="1 BHK">1 BHK</MenuItem>
                            <MenuItem value="2 BHK">2 BHK</MenuItem>
                            <MenuItem value="3 BHK">3 BHK</MenuItem>
                            <MenuItem value="4 BHK">4 BHK</MenuItem>
                            <MenuItem value="4+ BHK">4+ BHK</MenuItem>
                        </Select>
                    </FormControl>
                    <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                        <FormControl fullWidth margin='normal'>
                            <InputLabel>Start Month</InputLabel>
                            <Select
                                name="startMonth"
                                value={reviewData.startMonth}
                                label="Start Month"
                                onChange={handleSelectChange}
                            >
                                {months.map(month => (
                                    <MenuItem key={month} value={month}>{month}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth margin='normal'>
                            <InputLabel>Start Year</InputLabel>
                            <Select
                                name="startYear"
                                value={reviewData.startYear}
                                label="Start Year"
                                onChange={handleSelectChange}
                            >
                                {years.map(year => (
                                    <MenuItem key={year} value={year}>{year}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    {/* End Date Selection */}
                    <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                        <FormControl fullWidth>
                            <InputLabel>End Month</InputLabel>
                            <Select
                                name="endMonth"
                                value={reviewData.endMonth}
                                label="End Month"
                                onChange={handleSelectChange}
                            >
                                {months.map(month => (
                                    <MenuItem key={month} value={month}>{month}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel>End Year</InputLabel>
                            <Select
                                name="endYear"
                                value={reviewData.endYear}
                                label="End Year"
                                onChange={handleSelectChange}
                            >
                                {years.map(year => (
                                    <MenuItem key={year} value={year}>{year}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        multiline
                        rows={4}
                        id="homeAddress"
                        label="Home Address"
                        name="homeAddress"
                        placeholder="Please enter the complete address including pin code"
                        value={reviewData.homeAddress}
                        onChange={handleChange}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="rating-label">Rate your experience</InputLabel>
                        <Select
                            labelId="rating-label"
                            id="rating"
                            name="rating"
                            value={reviewData.rating.toString()}
                            label="Rate your experience of staying at this home"
                            onChange={handleSelectChange}
                        >
                            <MenuItem value={1}>Very Bad</MenuItem>
                            <MenuItem value={2}>Bad</MenuItem>
                            <MenuItem value={3}>Average</MenuItem>
                            <MenuItem value={4}>Good</MenuItem>
                            <MenuItem value={5}>Very Good</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        multiline
                        rows={4}
                        id="reviewText"
                        label="Review Text"
                        name="reviewText"
                        value={reviewData.reviewText}
                        onChange={handleChange}
                    />
                </Box>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center', p: 2 }}>
                <Button
                    variant="contained"
                    sx={{ width: '75%' }}
                    onClick={handleSubmit}
                >
                    {isEditing ? 'Update Review' : 'Submit Review'}
                </Button>
            </CardActions>
            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleCloseSnackbar} message={isEditing ? "Review Updated successfully!" : "Review added successfully!"}/>
        </Card>
    );
};

export default AddEditReview;
