import axios from "axios";



export class ProfileService {
    baseUrl: string;

    constructor() {
        if (process.env.NODE_ENV === 'production') {
            this.baseUrl = 'https://happyhomz.in:5000/api';
        } else {
            this.baseUrl = 'http://localhost:5000/api';
        }
    }

    public async getUser(userId: string): Promise<any> {
        const url = `${this.baseUrl}/users/${userId}`;
        const token = localStorage.getItem('token'); // Assuming token is stored in localStorage

        if (!token) {
            console.error("No token found in localStorage");
            return null; // Handle the case where no token is stored
        }

        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        try {
            const response = await axios.get(url, config);
            if (response.status === 200) {
                return response.data;
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                console.error("Error fetching user:", error.response.status, error.response.data);
                return null; // Handle error or throw it, depending on your error handling strategy
            } else {
                console.error("Unknown error occurred:", error);
                return null;
            }
        }
    }


}