import React, { useContext, useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItemButton, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link as RouterLink } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';

const Header = () => {
    const { isLoggedIn, logout } = useContext(AuthContext);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
    };

    const performLogout = () => {
        // Perform cleanup of local storage and other states
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('tokenExpiration');
        logout(); // Context method to handle user state
        handleDrawerClose(); // Close any open UI elements
        window.location.href = '/'; // Redirect to home after logout
    };

    const NavigationLinks = () => (
        <>
            <ListItemButton component={RouterLink} to="/my-profile" onClick={handleDrawerClose}>My Profile</ListItemButton>
            <ListItemButton component={RouterLink} to="/add-review" onClick={handleDrawerClose}>Add Review</ListItemButton>
            <ListItemButton component={RouterLink} to="/search-reviews" onClick={handleDrawerClose}>Search Reviews</ListItemButton>
            <ListItemButton onClick={performLogout}>Logout</ListItemButton>
        </>
    );

    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    <RouterLink to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                        HappyHomz
                    </RouterLink>
                </Typography>
                {isLoggedIn ? (
                    <>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            onClick={handleDrawerOpen}
                            sx={{ ml: 2, display: { sm: 'none' } }} // Only show the burger menu icon on small screens
                        >
                            <MenuIcon />
                        </IconButton>
                        <Drawer anchor="right" open={isDrawerOpen} onClose={handleDrawerClose}>
                            <List>
                                <NavigationLinks />
                            </List>
                        </Drawer>
                        <Box sx={{ display: { xs: 'none', sm: 'block' } }}> {/* Hide the buttons on small screens */}
                            <Button color="inherit" component={RouterLink} to='/my-profile'>My Profile</Button>
                            <Button color="inherit" component={RouterLink} to='/add-review'>Add Review</Button>
                            <Button color="inherit" component={RouterLink} to='/search-reviews'>Search Reviews</Button>
                            <Button color="inherit" onClick={performLogout}>Logout</Button>
                        </Box>
                    </>
                ) : (
                    <Button color="inherit" component={RouterLink} to='/login'>Login</Button>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Header;
