import React, { useContext, useState } from 'react';
import {
    Box, Container, Typography, Grid, Paper, Button, Avatar, Card, Theme,
    Divider, CardContent, useTheme
} from '@mui/material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import RateReviewIcon from '@mui/icons-material/RateReview';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';

interface InfoCardProps {
    title: string;
    description: string;
    IconComponent: React.ElementType;
    theme: Theme;
}

const InfoCard = ({ title, description, IconComponent, theme }: InfoCardProps) => (
    <Paper elevation={3} sx={{ p: 4, textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
        <Avatar sx={{ bgcolor: theme.palette.primary.main, width: 56, height: 56 }}>
            <IconComponent fontSize="large" />
        </Avatar>
        <Typography variant="h6" gutterBottom component="div">
            {title}
        </Typography>
        <Typography variant="body1">
            {description}
        </Typography>
    </Paper>
);

const ReviewCard = ({ review }: { review: { name: string; date: string; text: string } }) => (
    <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 4 }}>
        <Typography variant="h6" gutterBottom>{review.name}</Typography>
        <Typography variant="subtitle1">{review.date}</Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>{review.text}</Typography>
    </Card>
);

const HomePage = () => {
    const theme = useTheme();
    const { isLoggedIn } = useContext(AuthContext);
    const reviews = [
        { name: "Ashwin Prasad", date: "April 12, 2024", text: "I was lucky to find this website. It helped me avoid a home where the earlier tenant has a bad experience." },
        { name: "Abhijit Arora", date: "March 03, 2024", text: "Useful website. I used it to make sure the home I rented did not have any issues." },
        { name: "Chirantan Kumar", date: "March 30, 2024", text: "A good way for tenants like me to know any issues before hand." }
    ];
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep + 1) % reviews.length);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep - 1 + reviews.length) % reviews.length);
    };

    const cardDetails = [
        {
            title: "Get Real Reviews",
            description: "Know about the place you plan to rent, before you make a decision.",
            IconComponent: ApartmentIcon
        },
        {
            title: "Share Experience",
            description: "Share your reviews and guide future tenants in making the right decision.",
            IconComponent: RateReviewIcon
        },
    ];

    return (
        <Box sx={{ py: 8 }}>
            <Container maxWidth="lg">
                <Card sx={{ m: 2, overflow: 'hidden', maxWidth: '80%', mx: 'auto' }}>
                    <CardContent>
                        <Container maxWidth="md">
                            <Box textAlign="center" mb={6}>
                                <Typography variant="h3" component="h1" gutterBottom>
                                    Empowering Tenants
                                </Typography>
                                <Typography variant="subtitle1" color="textSecondary" paragraph>
                                    Share your experiences of renting, the good and the bad.
                                </Typography>
                            </Box>
                            <Grid container spacing={4} justifyContent="center">
                                {cardDetails.map((card, index) => (
                                    <Grid item xs={12} sm={6} md={4} key={index}>
                                        <InfoCard {...card} theme={theme} />
                                    </Grid>
                                ))}
                            </Grid>
                            <Box my={4} textAlign="center">
                            {!isLoggedIn && (
                                    <Button variant="contained" color="primary" size="large" component={Link} to="/register">
                                        Register Now
                                    </Button>
                                )}
                            </Box>
                            <Divider sx={{ my: 4 }} />
                            <Typography variant="h4" component="h2" mt={2} mb={2} textAlign="center">
                               Our users love us!
                            </Typography>
                            <Box sx={{ mb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <ReviewCard review={reviews[activeStep]} />
                                <Box sx={{ display: 'flex', mt: 2 }}>
                                    <Button onClick={handleBack} sx={{ mr: 1 }}>
                                        <ArrowBackIosNewIcon />
                                    </Button>
                                    <Button onClick={handleNext}>
                                        <ArrowForwardIosIcon />
                                    </Button>
                                </Box>
                            </Box>
                        </Container>
                    </CardContent>
                </Card>
            </Container>
        </Box>
    );
};

export default HomePage;
