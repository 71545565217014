import React, { useEffect, useState } from 'react';
import { AuthService } from '../../services/AuthService';
import { RegisterData } from '../../types/AuthTypes';
import { Grid, Box, TextField, Button, Typography, Alert, Card, CardContent, Container } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

const Register = () => {
    const [registerData, setRegisterData] = useState<RegisterData>({
        firstName: '',
        lastName: '',
        mobileNumber: '',
        password: '',
        confirmPassword: '',
    });

    const authService = new AuthService();

    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [RegisterSuccess, setRegisterSuccess] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRegisterData({
            ...registerData,
            [event.target.name]: event.target.value,
        });
    };


    // UseEffect to navigate after Register success
    useEffect(() => {
        if (RegisterSuccess) {
            setTimeout(() => {
                navigate('/login');
            }, 2000); // Ensure this matches Snackbar's duration
        }
    }, [RegisterSuccess, navigate]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (registerData.password !== registerData.confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        try {
            const response = await authService.register(registerData);
            if (response.success) {
                setOpenSnackbar(true);
                setRegisterSuccess(true);
            } else {
                setError(response.message); // Set error message from login attempt
            }
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError("An unknown error occurred");
            }
        }
    };

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={8} md={4}>
                <Card sx={{ mt: 4 }}>
                    <CardContent>
                        <Typography variant="h4" align="center" sx={{ mb: 3 }}>
                            Register
                        </Typography>
                        {error && <Alert severity="error" sx={{ width: '100%', mb: 2 }}>{error}</Alert>}
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                name="firstName"
                                autoFocus
                                value={registerData.firstName}
                                onChange={handleChange}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                value={registerData.lastName}
                                onChange={handleChange}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="mobileNumber"
                                label="Mobile Number"
                                name="mobileNumber"
                                value={registerData.mobileNumber}
                                onChange={handleChange}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                value={registerData.password}
                                onChange={handleChange}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="confirmPassword"
                                label="Confirm Password"
                                type="password"
                                id="confirmPassword"
                                value={registerData.confirmPassword}
                                onChange={handleChange}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Register
                            </Button>
                            <Typography
                                variant="body2"
                                align="center"
                                sx={{ mt: 2 }}
                            >
                                Already Registered? <Link to="/login" style={{ textDecoration: 'none' }}>Login here</Link>
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default Register;