// CityList.ts
const CityList = [
    "Gurugram",
    "Noida",
    "New Delhi",
    "Greater Noida",
    "Ghaziabad",
    "Mumbai",
    "Bangalore",
    "Hyderabad",
    "Chennai",
    "Kolkata",
    "Pune",
    "Ahmedabad",
  ];
  
  export default CityList;
  