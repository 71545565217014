import React, { useState } from 'react';
import {
    Box, Container, Typography, Card, CardContent, FormControl, InputLabel, Select, MenuItem, CircularProgress,
    Grid, SelectChangeEvent, Rating, Button, Dialog, DialogActions, DialogContent, TextField,
    Pagination
} from '@mui/material';
import { ReviewService } from '../../services/ReviewService';
import CityList from '../../types/CityList';
import { ReviewData } from '../../types/ReviewTypes';

const reviewService = new ReviewService();

interface FetchReviewsParams {
    city?: string;
    mobileNumber?: string;
    page: number;
    rowsPerPage: number;
}

const SearchReviews = () => {
    const [selectedCity, setSelectedCity] = useState<string>('');
    const [mobileNumber, setMobileNumber] = useState<string>('');
    const [reviews, setReviews] = useState<ReviewData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedReview, setSelectedReview] = useState<ReviewData | null>(null);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);

    const handleCityChange = (event: SelectChangeEvent<string>) => {
        setSelectedCity(event.target.value);
    };

    const handleMobileNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMobileNumber(event.target.value);
    };

    const handleSearch = () => {
        fetchReviews({ city: selectedCity, mobileNumber: mobileNumber, page, rowsPerPage });
    };

    const fetchReviews = async ({ city, mobileNumber, page, rowsPerPage }: FetchReviewsParams) => {
        setLoading(true);
        const searchParams = city ? { city } : { mobileNumber };
        try {
            const searchData = await reviewService.searchReviews(searchParams);
            setLoading(false);
            if (searchData.success) {
                setReviews(searchData.reviews);
                const totalReviews = searchData.total ?? 0;
                setTotalPages(Math.ceil(totalReviews / rowsPerPage));
            } else {
                console.error('Error fetching reviews:', searchData.message);
                setReviews([]);
                setTotalPages(0);
            }
        } catch (error) {
            console.error('Failed to fetch reviews:', error);
            setLoading(false);
            setReviews([]);
            setTotalPages(0);
        }
    };

    const handleOpenDialog = (review: ReviewData) => {
        setSelectedReview(review);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        fetchReviews({ city: selectedCity, mobileNumber, page: newPage, rowsPerPage });
    };

    const handleRowsPerPageChange = (event: SelectChangeEvent<string>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(1); // Reset to first page with new row count
        fetchReviews({ city: selectedCity, mobileNumber, page: 1, rowsPerPage: newRowsPerPage });
    };

    return (
        <Container maxWidth="md">
            <Card sx={{ mt: 4 }}>
                <CardContent>
                    <Typography variant="h5" gutterBottom>
                        Search Reviews
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={5}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>City</InputLabel>
                                <Select
                                    name="city"
                                    labelId="city-select-label"
                                    value={selectedCity}
                                    label="City"
                                    onChange={handleCityChange}
                                >
                                    {CityList.map((city) => (
                                        <MenuItem key={city} value={city}>{city}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2} display="flex" alignItems="center" justifyContent="center">
                            <Typography variant="h6">OR</Typography>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Landlord Mobile Number"
                                    variant="outlined"
                                    value={mobileNumber}
                                    onChange={handleMobileNumberChange}
                                    inputProps={{ maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*' }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button variant="contained" onClick={handleSearch}>Search</Button>
                    </Box>
                </CardContent>
            </Card>
            {reviews.length > 0 && (
                <Card sx={{ mt: 4 }}>
                    <CardContent>
                        <Grid container spacing={2}>
                            {reviews.map((review, index) => (
                                <Grid item xs={12} sm={12} md={12} key={index}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h6">Address: {review.homeAddress}</Typography>
                                            <Typography color="textSecondary" component="div">
                                                Rating:
                                                <Rating
                                                    name="read-only-rating"
                                                    value={Number(review.rating)}
                                                    readOnly
                                                    precision={0.5}
                                                    size="small"
                                                />
                                            </Typography>
                                            <Box display="flex" justifyContent="flex-end" mt={2}>
                                                <Button variant="outlined" onClick={() => handleOpenDialog(review)}>Full Review</Button>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 4 }}>
                            <FormControl size="small">
                                <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
                                <Select
                                    labelId="rows-per-page-label"
                                    id="rows-per-page"
                                    value={rowsPerPage.toString()}
                                    onChange={handleRowsPerPageChange}
                                    label="Rows per page"
                                >
                                    {[10, 25, 50, 100].map(option => (
                                        <MenuItem key={option} value={option.toString()}>{option}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Pagination
                                count={totalPages}
                                page={page}
                                onChange={handleChangePage}
                                color="primary"
                            />
                        </Box>
                    </CardContent>
                </Card>
            )}
            <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md" sx={{ '& .MuiDialog-paper': { minWidth: '80%' } }}>
                <DialogContent>
                    <Grid container spacing={2}>
                        {selectedReview && (
                            <>
                                <Grid item xs={12}>
                                    <Typography gutterBottom variant="h6">
                                        Address: {selectedReview.homeAddress}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="textSecondary" gutterBottom>
                                        Name of Landlord: {selectedReview.landlordName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="textSecondary" gutterBottom>
                                        Period of Stay: {selectedReview.startMonth} {selectedReview.startYear} to {selectedReview.endMonth} {selectedReview.endYear}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="textSecondary" component="div" gutterBottom>
                                        Rating:
                                        <Rating
                                            value={Number(selectedReview.rating)}
                                            readOnly
                                            precision={0.5}
                                            size="small"
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography color="textSecondary" gutterBottom>
                                        Review: {selectedReview.reviewText}
                                    </Typography>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default SearchReviews;

