import { useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import HomePage from './components/Home/HomePage';
import Header from './components/Shared/Header';
import Footer from './components/Shared/Footer';
import AuthContext from './context/AuthContext';
import SearchReviews from './components/Search/SearchReviews';
import MyProfile from './components/MyProfile/MyProfile';
import AddEditReview from './components/Review/AddEditReview';

const App = () => {
    const { isLoggedIn } = useContext(AuthContext);

    return (
        <BrowserRouter>
            <Header />
            <Routes>
                <Route path="/" element={<HomePage />} />
                {isLoggedIn ? (
                    <>
                        <Route path="/my-profile" element={<MyProfile />} />
                        <Route path="/add-review" element={<AddEditReview />} />
                        <Route path="/edit-review/:reviewId" element={<AddEditReview />} />
                        <Route path="/search-reviews" element={<SearchReviews />} />
                    </>
                ) : (
                    <>
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                    </>
                )}
                <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    );
};

export default App;
