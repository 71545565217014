import axios from 'axios';
import { RegisterData, LoginData } from '../types/AuthTypes';

export class AuthService {
    baseUrl: string;

    constructor() {
        if (process.env.NODE_ENV === 'production') {
            this.baseUrl = 'https://happyhomz.in:5000/api';
        } else {
            this.baseUrl = 'http://localhost:5000/api';
        }
    }

    private validateRegisterData(data: RegisterData): string | null {
        if (!data.firstName || !data.lastName || !data.mobileNumber || !data.password || !data.confirmPassword) {
            return 'Please fill in all required fields.';
        }

        if (data.password !== data.confirmPassword) {
            return 'Passwords do not match.';
        }

        if (!data.mobileNumber.match(/^\d{10}$/)) { // Assuming 10-digit mobile numbers
            return 'Please enter a valid 10-digit mobile number.';
        }

        return null; // No errors
    }

    private validateLoginData(data: LoginData): string | null {
        if (!data.mobileNumber || !data.password) {
            return 'Please enter your mobile number and password.';
        }
        return null; // No errors
    }

    public async register(data: RegisterData): Promise<{ success: boolean, message: string }> {
        const validationError = this.validateRegisterData(data);
        if (validationError) {
            return { success: false, message: validationError };
        }

        try {
            await axios.post(`${this.baseUrl}/users/register`, data);
            return { success: true, message: 'User registered successfully.' };
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                // Extracting message from server response
                return { success: false, message: error.response.data.message || 'An error occurred during registration.' };
            } else {
                return { success: false, message: 'An unexpected error occurred during registration.' };
            }
        }
    }

    public async login(data: LoginData): Promise<{ success: boolean, message: string, token?: string }> {
        const validationError = this.validateLoginData(data);
        if (validationError) {
            return { success: false, message: validationError };
        }

        const url = this.baseUrl + '/users/login';

        try {
            const response = await axios.post(`${this.baseUrl}/users/login`, data);
            const { token, userId, expirationTime } = response.data;

            // Store login information in localStorage
            localStorage.setItem('token', token);
            localStorage.setItem('userId', userId);
            localStorage.setItem('tokenExpiration', expirationTime.toString());

            return { success: true, message: 'Login successful', token };
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                // Extracting message from server response
                return { success: false, message: error.response.data.message || 'An error occurred during login.' };
            } else {
                return { success: false, message: 'An unexpected error occurred during login.' };
            }
        }
    }
}
