import React from 'react';
import { Box, Typography, Grid, Link } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
  return (
    <Box mt={8} py={3} sx={{ bgcolor: 'primary.main', color: 'white' }}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={4}>
          <Typography variant="body2" align="center">
            © HappyHomz - {new Date().getFullYear()}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="body2" align="center">
            <Link href="/privacy-policy" color="inherit" sx={{ textDecoration: 'none' }}>
              Privacy Policy
            </Link>
            {' | '}
            <Link href="/terms-conditions" color="inherit" sx={{ textDecoration: 'none' }}>
              Terms & Conditions
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box display="flex" justifyContent="center">
            <Link href="https://facebook.com" color="inherit" sx={{ mx: 1 }}>
              <FacebookIcon />
            </Link>
            <Link href="https://twitter.com" color="inherit" sx={{ mx: 1 }}>
              <TwitterIcon />
            </Link>
            <Link href="https://instagram.com" color="inherit" sx={{ mx: 1 }}>
              <InstagramIcon />
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
