import axios, { AxiosResponse } from 'axios';
import { ReviewData } from '../types/ReviewTypes';
import { SearchData, SearchParams } from '../types/SearchTypes';

export class ReviewService {
    baseUrl: string;

    constructor() {
        if (process.env.NODE_ENV === 'production') {
            this.baseUrl = 'https://happyhomz.in:5000/api/reviews';
        } else {
            this.baseUrl = 'http://localhost:5000/api/reviews';
        }
    }

    public async addReview(reviewData: ReviewData): Promise<{ success: boolean, message: string }> {
        const token = localStorage.getItem('token'); // Assuming token is stored in localStorage

        if (!token) {
            console.error("No token found in localStorage");
            return { success: false, message: "Unexpected error. Please logout and login again." }; // Handle the case where no token is stored
        }

        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        try {
            const response = await axios.post(`${this.baseUrl}/add-review`, reviewData, config);

            return { success: response.data.success, message: response.data.message };
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                console.error("An error occurred while adding a review:", error.message);
                return { success: false, message: error.response?.data.message || "An error occurred during the request." };
            }
            console.error("An unexpected error occurred:", error);
            return { success: false, message: "An unexpected error occurred." };
        }
    }



    public async searchReviews(params: SearchParams): Promise<SearchData> {
        const token = localStorage.getItem('token');

        if (!token) {
            console.error("No token found in localStorage");
            return { success: false, message: "Unexpected error. Please logout and login again.", reviews: [] };
        }

        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        let queryParams = new URLSearchParams();
        if (params.city) {
            queryParams.append('city', params.city);
        }
        if (params.mobileNumber) {
            queryParams.append('mobileNumber', params.mobileNumber);
        }

        const url = `${this.baseUrl}/search-reviews?${queryParams.toString()}`;
        try {
            const response = await axios.get<SearchData>(url, config);
            if (response.data) {
                return response.data;
            } else {
                return { success: false, message: "No reviews found", reviews: [] };
            }
        } catch (error) {
            console.error('Error while fetching reviews:', error);
            return { success: false, message: "Error fetching reviews. Please try again.", reviews: [] };
        }
    }

    public async getUserReviews(userId: string): Promise<{ success: boolean, message: string, reviews: ReviewData[] }> {
        const token = localStorage.getItem('token');

        if (!token) {
            console.error("No token found in localStorage");
            return { success: false, message: "Unexpected error. Please logout and login again.", reviews: [] };
        }

        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const url = `${this.baseUrl}/get-reviews/${encodeURIComponent(userId)}`;
        try {
            const response = await axios.get<{ success: boolean, message: string, reviews: ReviewData[] }>(url, config);

            if (response.data && response.data.success) {
                return {
                    success: response.data.success,
                    message: response.data.message,
                    reviews: response.data.reviews
                };
            } else {
                return { success: false, message: "No reviews found", reviews: [] };
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                console.error("An error occurred while fetching reviews:", error.message);
                return { success: false, message: error.response.data.message || "An error occurred during the request.", reviews: [] };
            }
            console.error("An unexpected error occurred:", error);
            return { success: false, message: "An unexpected error occurred.", reviews: [] };
        }
    }

    public async getReview(reviewId: string): Promise<{ success: boolean, message: string, review: ReviewData | null }> {
        const token = localStorage.getItem('token');

        if (!token) {
            console.error("No token found in localStorage");
            return { success: false, message: "Unexpected error. Please logout and login again.", review: null };
        }
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        const url = `${this.baseUrl}/get-review/${encodeURIComponent(reviewId)}`;

        try {
            const response = await axios.get<{ success: boolean, message: string, review: ReviewData }>(url, config);

            if (response.data && response.data.success) {
                return { success: response.data.success, message: response.data.message, review: response.data.review };
            }

            return { success: false, message: "Review not found", review: null };
        }
        catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                console.error("An error occurred while fetching a review:", error.message);
                return { success: false, message: error.response.data.message || "An error occurred during the request.", review: null };
            }
            console.error("An unexpected error occurred:", error);
            return { success: false, message: "An unexpected error occurred.", review: null };
        }
    }

    public async deleteReview(reviewId: string): Promise<{ success: boolean, message: string }> {
        const token = localStorage.getItem('token');

        if (!token) {
            console.error("No token found in localStorage");
            return { success: false, message: "Unexpected error. Please logout and login again." };
        }

        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const url = `${this.baseUrl}/delete-review/${encodeURIComponent(reviewId)}`;

        try {
            const response = await axios.delete(url, config);

            if (response.data && response.data.success) {
                return { success: true, message: "Review deleted successfully." };
            } else {
                return { success: false, message: "Failed to delete review." };
            }

        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                console.error("An error occurred while deleting a review:", error.message);
                return { success: false, message: error.response.data.message || "An error occurred during the request." };
            }
            console.error("An unexpected error occurred:", error);
            return { success: false, message: "An unexpected error occurred." };
        }
    }

    public async updateReview(reviewId: string, reviewData: ReviewData): Promise<{ success: boolean, message: string }> {
        const token = localStorage.getItem('token');

        if (!token) {
            console.error("No token found in localStorage");
            return { success: false, message: "Unexpected error. Please logout and login again." };
        }

        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const url = `${this.baseUrl}/update-review/${encodeURIComponent(reviewId)}`;

        try {
            const response = await axios.put(url, reviewData, config);

            if (response.data && response.data.success) {
                return { success: true, message: "Review updated successfully." };
            }

            return { success: false, message: "Failed to update review." };
        }
        catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                console.error("An error occurred while updating a review:", error.message);
                return { success: false, message: error.response.data.message || "An error occurred during the request." };
            }
            console.error("An unexpected error occurred:", error);
            return { success: false, message: "An unexpected error occurred." };
        }
    }
}