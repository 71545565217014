import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // For programmatic navigation
import { Grid, Box, TextField, Button, Typography, Alert as MuiAlert, Card, CardContent, Snackbar, AlertProps } from '@mui/material';
import { AuthService } from '../../services/AuthService';
import { LoginData } from '../../types/AuthTypes';
import AuthContext from '../../context/AuthContext';

// Alert component for Snackbar
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Login = () => {
    const [loginData, setLoginData] = useState<LoginData>({
        mobileNumber: '',
        password: '',
    });
    const authService = new AuthService();
    const [error, setError] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false); 
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();
    const [loginSuccess, setLoginSuccess] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoginData({
            ...loginData,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            const response = await authService.login(loginData);
            if (response.success) {
                setOpenSnackbar(true);
                login(response.token!);
                setLoginSuccess(true);
            } else {
                setError(response.message);
            }
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError('An unknown error occurred');
            }
        }
    };

    useEffect(() => {
        if (loginSuccess) {
            setTimeout(() => {
                navigate('/');
            }, 2000);
        }
    }, [loginSuccess, navigate]);

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={8} md={4}>
                <Card sx={{ mt: 4 }}>
                    <CardContent>
                        <Typography variant="h4" align="center" sx={{ mb: 3 }}>
                            Login
                        </Typography>
                        {error && <MuiAlert severity="error" sx={{ mb: 2 }}>{error}</MuiAlert>}
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="mobileNumber"
                                label="Mobile Number"
                                name="mobileNumber"
                                autoComplete="mobile-number"
                                autoFocus
                                value={loginData.mobileNumber}
                                onChange={handleChange}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={loginData.password}
                                onChange={handleChange}
                            />
                            <Typography
                                align="right"
                                sx={{
                                    mb: 2,
                                    mt: 1,
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                    color: 'primary.main',
                                }}
                                onClick={() => navigate('/forgot-password')}
                            >
                                Forgot Password?
                            </Typography>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Login
                            </Button>
                            <Typography
                                variant="body2"
                                align="center"
                                sx={{ mt: 2 }}
                            >
                                Not Registered? <Link to="/register" style={{ textDecoration: 'none' }}>Register here</Link>
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Login successful!
                </Alert>
            </Snackbar>
        </Grid>
    );
};

export default Login;
