import React, { useEffect, useState, useContext } from 'react';
import { Box, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogActions, DialogContent, Grid, Rating, Snackbar, Alert, Typography, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import { ReviewService } from '../../services/ReviewService';
import { ProfileService } from '../../services/ProfileService';
import { ReviewData } from '../../types/ReviewTypes';
import { UserData } from '../../types/UserTypes';

const MyProfile = () => {
    const [user, setUser] = useState<UserData>({} as UserData);
    const [reviews, setReviews] = useState<ReviewData[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedReview, setSelectedReview] = useState<ReviewData | null>(null);
    const { isLoggedIn } = useContext(AuthContext);
    const reviewService = new ReviewService();
    const profileService = new ProfileService();
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) {
            fetchProfile();
            fetchReviews();
        }
    }, [isLoggedIn]);

    const fetchProfile = async () => {
        try {
            const userId = localStorage.getItem('userId')!;
            const result = await profileService.getUser(userId);
            if (result.message === "User found") {
                setUser(result.user);
            } else {
                setError("Failed to fetch user");
            }
        } catch (error) {
            setError("Error fetching user");
        } finally {
            setLoading(false);
        }
    };

    const fetchReviews = async () => {
        try {
            const userId = localStorage.getItem('userId')!;
            const result = await reviewService.getUserReviews(userId);
            if (result.success) {
                setReviews(result.reviews);
            } else {
                setError("Failed to fetch reviews");
            }
        } catch (error) {
            setError("Error fetching reviews");
        }
        setLoading(false);
    };

    const handleUpdateProfile = () => {
        setEditMode(!editMode);
    };

    const handleOpenDialog = (review: ReviewData) => {
        setSelectedReview(review);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleDeleteReview = async (reviewId: string) => {
        try {
            const result = await reviewService.deleteReview(reviewId);
            if (result.success) {
                setReviews(reviews.filter(review => review.id !== reviewId));
            } else {
                setError(result.message);
            }
        } catch (error) {
            setError("Error deleting review");
        }
    };

    const confirmDelete = (reviewId: string) => {
        if (window.confirm("Are you sure you want to delete this review?")) {
            handleDeleteReview(reviewId);
        }
    };

    return (
        <Container maxWidth="md">
            <Card sx={{ mt: 4 }}>
                <CardContent>
                    <Typography variant="h4" gutterBottom>My Profile</Typography>
                    <TextField
                        label="First Name"
                        value={user.firstName || ''}
                        onChange={(e) => setUser({ ...user, firstName: e.target.value })}
                        InputProps={{ readOnly: !editMode, style: { color: editMode ? 'black' : 'grey' } }}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="Last Name"
                        value={user.lastName || ''}
                        onChange={(e) => setUser({ ...user, lastName: e.target.value })}
                        InputProps={{ readOnly: !editMode, style: { color: editMode ? 'black' : 'grey' } }}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label="Mobile Number"
                        value={user.mobile || ''}
                        InputProps={{ readOnly: true, style: { color: 'grey' } }}
                        fullWidth
                        margin="dense"
                    />
                    <Box display="flex" justifyContent="center" mt={2}>
                        <Button
                            variant="contained"
                            onClick={handleUpdateProfile}
                            sx={{ backgroundColor: editMode ? "secondary.main" : "primary.main" }}
                        >
                            {editMode ? "Save Changes" : "Edit Profile"}
                        </Button>
                    </Box>
                </CardContent>
            </Card>
            <Card sx={{ mt: 4 }}>
                <CardContent>
                    <Typography variant="h5" gutterBottom>My Reviews</Typography>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Grid container spacing={2}>
                            {reviews.length > 0 ? reviews.map((review) => (
                                <Grid item xs={12} key={review.id}>
                                    <Card raised>
                                        <CardContent>
                                            <Typography variant="h6">{review.homeAddress}</Typography>
                                            <Typography color="textSecondary">
                                                Rating: <Rating value={Number(review.rating)} readOnly />
                                            </Typography>
                                            <Typography>{review.reviewText}</Typography>
                                            <Box display="flex" justifyContent="space-between" mt={2}>
                                                <Button variant="outlined" onClick={() => handleOpenDialog(review)}>Read</Button>
                                                <Button variant="outlined" onClick={() => navigate(`/edit-review/${review.id}`)}>Edit</Button>
                                                <Button variant="contained" color="error" onClick={() => confirmDelete(review.id)}>Delete</Button>

                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )) : (
                                <Box display="flex" justifyContent="center" mt={2}>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        No reviews added yet.
                                    </Typography>
                                </Box>
                            )}
                        </Grid>
                    )}
                </CardContent>
            </Card>
            {selectedReview && (
                <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md">
                    <DialogContent>
                        <Typography variant="h6">{`Address: ${selectedReview.homeAddress}`}</Typography>
                        <Typography>{`Landlord: ${selectedReview.landlordName}`}</Typography>
                        <Typography>{`City: ${selectedReview.city}`}</Typography>
                        <Typography>{`Home Type: ${selectedReview.homeType}`}</Typography>
                        <Typography>{`Configuration: ${selectedReview.homeConfiguration}`}</Typography>
                        <Typography>{`Period: ${selectedReview.startMonth} ${selectedReview.startYear} - ${selectedReview.endMonth} ${selectedReview.endYear}`}</Typography>
                        <Rating value={Number(selectedReview.rating)} readOnly />
                        <Typography>{selectedReview.reviewText}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>Close</Button>
                    </DialogActions>
                </Dialog>
            )}
            {error && (
                <Snackbar open={true} autoHideDuration={6000}>
                    <Alert severity="error">{error}</Alert>
                </Snackbar>
            )}
        </Container>
    );
};

export default MyProfile;
